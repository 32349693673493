<template>
    <v-container fluid>
        <v-form @submit.prevent="getReservations">
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <v-card flat>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12">
                                <v-subheader class="headline">{{ $t('reservation') }}</v-subheader>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="py-0" cols="12" sm="4">
                                <ValidationProvider ref="restaurant"
                                                    v-slot="{ errors, valid }">
                                    <v-select v-model="restaurant" :items="itemRestaurant"
                                              :error-messages="errors" :disabled="loadingRestaurant" item-text="name"
                                              item-value="id" return-object
                                              prepend-icon="mdi-food-variant" :label="$t('restaurant')"
                                              clearable></v-select>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="4">
                                <ValidationProvider ref="phone" rules="phone"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="phoneRaw" type="tel" v-mask="['+# (###) ###-##-##', '+## (###) ###-##-##', '+### (###) ###-##-##']"
                                                  :error-messages="errors" :disabled="loading"
                                                  prepend-icon="mdi-phone" :label="$t('phone')"
                                                  color="primary" clearable>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="4">
                                <v-dialog ref="reserveDate" v-model="reserveDate" width="290px">
                                    <v-date-picker ref="pickerTourFrom" v-model="reserve_date"
                                                   :locale="lang" @change="reserveDate = false"></v-date-picker>
                                </v-dialog>
                                <ValidationProvider ref="reserve_date" rules="date_format"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="reserve_date" :error-messages="errors"
                                                  :disabled="loading" :label="$t('date_from')"
                                                  prepend-icon="mdi-calendar" color="primary" readonly
                                                  @click.stop="reserveDate = !reserveDate"
                                                  clearable @click:clear="reserve_date = null"></v-text-field>
                                </ValidationProvider>
                            </v-col>
                        </v-row>

                    </v-card-text>
                    <v-card-actions class="px-4">
                        <v-row>
                            <v-col cols="12" sm="3" md="2">
                                <v-select :value="perPage" :items="perPageItems" :disabled="loading"
                                          :label="$t('items_per_page')" prepend-icon="mdi-counter"
                                          @input="perPage = options.itemsPerPage = Number($event)"
                                          hide-details dense></v-select>
                            </v-col>
                            <v-col cols="12" sm="9" md="10"
                                   class="d-flex justify-center justify-sm-end align-self-center">
                                <v-btn type="submit" :disabled="invalid || loading"
                                       :block="$vuetify.breakpoint.xsOnly" color="primary">
                                    {{ $t('search') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-form>


        <v-card flat>
            <v-card-text>
                <v-data-table :headers="headers" :items="reservationItems" :options.sync="options" :page.sync="page"
                              :items-per-page="perPage" :server-items-length="totalReservations" :sort-by.sync="sortBy"
                              :sort-desc.sync="sortDir" :loading="loading" :locale="lang"
                              :loading-text="$t('loading_please_wait')" class="elevation-1"
                              hide-default-footer
                              disable-pagination
                              single-expand :expanded.sync="expanded" item-key="uuid" show-expand
                >
                    <template v-slot:item.datetime="{ item }">
                        <div :class="item.deleted ? 'red--text' : ''">
                            {{  item.datetime ? $moment(item.datetime).format('DD.MM.YYYY HH:mm') : ''}}
                            {{ item.deleted ? ' (' + $t('cancelled') + ')' : '' }}
                        </div>
                    </template>
                    <template v-slot:item.prepayment="{ item }">
                        {{ item.prepayment ? $t('yes') : $t('no')}}
                    </template>


                    <template v-slot:item.action="{ item }" class="text-center">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" class="mr-2" @click="showDetailsReservation(item)" :title="$t('details')">
                                    mdi-card-text-outline
                                </v-icon>
                            </template>
                            <span>{{$t('reservation_details')}}</span>
                        </v-tooltip>

                        <v-tooltip bottom >
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" class="mr-2" @click="editReservation(item)" :title="$t('edit')"

                                >
                                    mdi-square-edit-outline

                                </v-icon>
                            </template>
                            <span>{{$t('edit')}}</span>
                        </v-tooltip>





                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" @click="CancelReservation(item)" :title="$t('cancel')"
                                 >
                                    mdi-delete-outline
                                </v-icon>
                            </template>
                            <span>{{ $t('cancel') }}</span>
                        </v-tooltip>


                    </template>


                    <template v-slot:expanded-item="{ headers, item}">
                        <td :colspan="headers.length">
                            <v-row class="py-2 my-2">
                                <v-col cols="12" sm="12">
                                    <v-hover
                                        v-slot="{ hover }"
                                        open-delay="200"
                                    >
                                        <v-card
                                            :elevation="hover ? 16 : 2"
                                            :class="{ 'on-hover': hover }"
                                        >
                                            <v-card-text>
                                                <p class="py-0 my-0" v-if="item.restaurant ? true : false"><b>{{ $t('restaurant') }}:</b> {{ item.restaurant }}</p>
                                                <p class="py-0 my-0" v-if="item.name ? true : false"><b>{{ $t('client_name') }}:</b> {{ item.name }}</p>
                                                <p class="py-0 my-0" v-if="item.phone ? true : false"><b>{{ $t('phone') }}:</b> {{ item.phone }}</p>
                                                <p class="py-0 my-0" v-if="item.people ? true : false"><b>{{ $t('number_people') }}:</b> {{ item.people }}</p>
                                                <p class="py-0 my-0" v-if="item.table_number ? true : false"><b>{{ $t('restaurant_table_number') }}:</b> {{ item.table_number }}</p>
                                                <p class="py-0 my-0" v-if="item.datetime ? true : false"><b>{{ $t('datetime') }}:</b>   {{  item.datetime ? $moment(item.datetime).format('DD.MM.YYYY HH:mm') : ''}}</p>
                                                <p class="py-0 my-0"><b>{{ $t('prepayment') }}:</b>  {{ item.prepayment ? $t('yes') : $t('no')}}</p>
                                                <p class="py-0 my-0" v-if="item.prepayment_amount ? true : false"><b>{{ $t('prepayment_amount') }}:</b>  {{  item.prepayment_amount }}</p>
                                                <p class="py-0 my-0" v-if="item.comment ? true : false"><b>{{ $t('comment') }}:</b>  {{  item.comment }}</p>


                                                </v-card-text>
                                        </v-card>
                                    </v-hover>
                                </v-col>
                            </v-row>


                        </td>
                    </template>

                </v-data-table>
                <v-card-actions class="justify-center">
                    <v-pagination v-show="totalReservations > perPage" v-model="page" :total-visible="totalVisiblePag"
                                  :length="pageCount" :disabled="loading"></v-pagination>
                </v-card-actions>
            </v-card-text>

        </v-card>

        <v-dialog v-if="dialogDetails" v-model="dialogDetails" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card>
                <v-toolbar dark color="primary">
                    <v-toolbar-title>{{ $t('details') }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn icon dark @click="cancelDetails()">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>

                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="6" :class="this.$vuetify.breakpoint.name !== 'xs' ? 'text-right' : 'pb-0'">
                            <v-col cols="12" class="py-1"><b> {{ $t('restaurant')}}: </b>{{ detailsItems.restaurant ? detailsItems.restaurant :  $t('no_data')  }}</v-col>
                            <v-col cols="12" class="py-1"><b>{{ $t('client_name')}}: </b>{{ detailsItems.name ? detailsItems.name :  $t('no_data')  }}</v-col>
                            <v-col cols="12" class="py-1"><b>{{ $t('phone')}}: </b>{{ detailsItems.phone ? detailsItems.phone :  $t('no_data')  }}</v-col>
                            <v-col cols="12" class="py-1"><b>{{ $t('datetime')}}: </b> {{ detailsItems.datetime ?  $moment(detailsItems.datetime).format('DD.MM.YYYY HH:mm') :  $t('no_data')  }}</v-col>
                        </v-col>
                        <v-col cols="12" sm="6" :class="this.$vuetify.breakpoint.name === 'xs' ? 'py-0' : ''">
                            <v-col cols="12" class="py-1"><b>{{ $t('number_people')}}: </b>{{ detailsItems.people ? detailsItems.people :  $t('no_data')  }}</v-col>
                            <v-col cols="12" class="py-1"><b> {{ $t('restaurant_table_number')}}:</b> {{ detailsItems.table_number ? detailsItems.table_number :  $t('no_data')  }}</v-col>
                            <v-col cols="12" class="py-1"><b>{{ $t('prepayment')}}: </b>{{ detailsItems.prepayment ?  $t('yes') : $t('no')  }}</v-col>
                            <v-col cols="12" class="py-1"><b>{{ $t('prepayment_amount')}}: </b> {{ detailsItems.prepayment_amount ? detailsItems.prepayment_amount :  $t('no_data')  }}</v-col>
                        </v-col>
                        <v-col cols="12" class="text-center" v-if="detailsItems.comment ? true : false">
                            <b>{{ $t('comment')}}: </b> {{ detailsItems.comment ? detailsItems.comment :  $t('no_data')  }}
                        </v-col>
                    </v-row>
                    <v-divider class="my-4"></v-divider>

                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-if="reservationData" v-model="dialogReservation" max-width="800" persistent eager>
            <v-card>
                <ValidationObserver ref="observerForm" v-slot="{ invalid, validated, passes, validate }">
                    <v-card-title>
                        <span class="headline">
                            {{ $t('reservation') }}
                        </span>
                    </v-card-title>
                    <v-card-text class="pt-5">
                        <v-row>
                            <v-col class="py-0" cols="12" sm="4">
                                <ValidationProvider ref="type_table" rules="required"
                                                    v-slot="{ errors, valid }">
                                    <v-select v-model="type_table" :items="itemTypeTable"
                                              :error="!valid"
                                              :error-messages="errors" :disabled="loadingTypeTable"
                                              item-text="name" item-value="id" return-object
                                              @change="table_number=null;getTableNumber(type_table.id)"
                                              prepend-icon="mdi-table-furniture" :label="$t('type_table')"
                                              clearable></v-select>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="4">
                                <ValidationProvider ref="table_number" rules="required"
                                                    v-slot="{ errors, valid }">
                                    <v-select v-model="table_number" :items="itemTableNumber"
                                              :error="!valid"
                                              :error-messages="errors" :disabled="loadingTableNumber" item-text="number"
                                              item-value="id" return-object
                                              prepend-icon="mdi-table-chair" :label="$t('table_number')"
                                              clearable></v-select>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="4">
                                <ValidationProvider ref="people" name="option"
                                                    rules="required"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="people" type="text"
                                                  :error="!valid" :error-messages="errors"
                                                  :disabled="loading" prepend-icon="mdi-account-group"
                                                  :label="$t('number_people')" color="primary"
                                                  clearable>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="8">
                                <v-dialog ref="new_reserveDate" v-model="new_reserveDate" width="290px">
                                    <v-date-picker  v-model="new_reserve_date"
                                                    :locale="lang" @change="new_reserveDate = false"></v-date-picker>
                                </v-dialog>
                                <ValidationProvider ref="new_reserve_date" rules="required|date_format"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="new_reserve_date" :error-messages="errors"
                                                  :disabled="loading" :label="$t('reserve_date')"
                                                  prepend-icon="mdi-calendar" color="primary" readonly
                                                  @click.stop="new_reserveDate = !new_reserveDate"
                                                  clearable @click:clear="reserve_date = null"></v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="4">
                                <v-dialog ref="reserveTime" v-model="reserveTime" width="290px">
                                    <v-time-picker
                                        v-model="reserve_time"
                                        @change="reserveTime = false"
                                        class="mt-4"
                                        format="24hr"
                                        scrollable

                                    ></v-time-picker>
                                </v-dialog>
                                <ValidationProvider ref="reserve_time" rules="required"
                                                    v-slot="{ errors, valid }">


                                    <v-text-field v-model="reserve_time" type="text" :disabled="loading"
                                                  :error="!valid" :error-messages="errors"
                                                  prepend-icon="mdi-clock-outline"
                                                  :label="$t('reserve_time')"
                                                  color="primary"
                                                  @click.stop="reserveTime = !reserveTime"
                                                  clearable @click:clear="reserve_time = null"
                                    >
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col cols="12">
                                <ValidationProvider ref="notes" rules="min:3|max:256"
                                                    v-slot="{ errors, valid }">
                                    <v-textarea v-model="comment" type="text" :error-messages="errors"
                                                :disabled="loading" :label="$t('comment')" rows="5"
                                                color="primary" auto-grow clearable outlined>
                                    </v-textarea>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="gray darken-1" class="ml-1 ml-sm-2" text @click="closeDialogReservation">
                            {{ $t('cancel') }}
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text :loading="loadingSaveReservation"
                               :disabled="invalid || loadingSaveReservation" class="mr-1 mr-sm-2"
                               @click="saveReservation()">
                            {{ $t('save') }}
                        </v-btn>
                    </v-card-actions>
                </ValidationObserver>
            </v-card>
        </v-dialog>






    </v-container>
</template>
<script>

import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {mapGetters} from "vuex";
import {mask} from 'vue-the-mask'

export default {
    name: "RestaurantReservations",
    directives: {
        mask,
    },
    components: {
        ValidationObserver,
        ValidationProvider
    },
    data() {
        return {
            phoneRaw: null,
            loadingRestaurant: false,
            restaurant: null,
            itemRestaurant: [],
            loading: false,
            dialogDetails: false,
            detailsItems:[],
            options: {},
            reservationItems: [],

            expanded: [],


            sortBy: "id",
            sortDir: false,
            page: 1,
            pageCount: 0,
            perPage: 5,
            totalVisiblePag: 5,
            totalReservations: 0,
            reserve_date: null,
            reserveDate: false,
            dialogReservation: false,
            reservationData: {},
            type_table: null,
            itemTypeTable: [],
            loadingTypeTable: false,
            table_number: null,
            itemTableNumber: [],
            loadingTableNumber: false,
            loadingSaveReservation: false,
            new_reserve_date: null,
            new_reserveDate: false,
            new_dialogReservation: false,
            reserveTime: false,
            reserve_time: null,
            comment: null,
            reserve_id: null,
            people: null,

            headers: [
                {
                    text: this.$t('datetime'),
                    align: "left",
                    sortable: false,
                    value: "datetime"
                },
                {
                    text: this.$t('restaurant'),
                    align: "left",
                    sortable: false,
                    value: "restaurant"
                },
                {
                    text: this.$t('client_name'),
                    align: "left",
                    sortable: false,
                    value: "name"
                },
                {
                    text: this.$t('phone'),
                    align: "center",
                    sortable: false,
                    value: "phone"
                },
                {
                    text: this.$t('number_people'),
                    align: "center",
                    sortable: false,
                    value: "people"
                },
                {
                    text: this.$t('actions'),
                    align: "center",
                    value: 'action',
                    sortable: false,
                    width: 220,
                },
                {
                    text: '',
                    value: 'data-table-expand',
                    width: 40,
                    class: "handle",
                },
            ],
        };
    },
    computed: {
        ...mapGetters(['lang', 'itemsPerPage', 'perPageItems', 'phoneMask']),
        phone: function () {
            return this.changePhone(this.phoneRaw)
        },
    },
    async mounted() {
        this.options.itemsPerPage = this.perPage = this.itemsPerPage
        await this.getRestaurant()
        await this.getTypeTable()
    },
    watch: {
        options: {
            handler() {
                this.getReservations()
            },
            deep: false
        },


    },
    methods: {
        async getTypeTable() {
            this.loadingTypeTable = true;
            let params = {}
            if (this.language) {
                params.language = this.language
            }
            await this.$http
                .get(`admin/type_table/`, {params: params})
                .then(res => {
                    this.itemTypeTable = res.body.data
                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_get_list_type_tables'));
                    this.itemTypeTable = []

                })
                .finally(end => {
                    this.loadingTypeTable = false
                });
        },
        async editReservation(item) {
            this.reserve_id = item.id
            if(item.type_table && item.type_table.id){
                this.type_table = item.type_table.id
            }
            this.people = item.people
            this.new_reserve_date = item.date
            this.reserve_time = item.time
            this.comment = item.comment
            await this.getTableNumber(this.type_table);
            if(item.table && item.table.id) {
                this.table_number = item.table.id
            }
            this.reservationData = Object.assign({}, item)
            this.dialogReservation = true
        },
        closeDialogReservation() {
            this.dialogReservation = false
            this.type_table = null
            this.new_reserve_date = null
            this.reserve_time = null
            this.table_number = null
            this.comment = null
            this.reserve_id = null
            this.people = null
            this.$nextTick(() => {
                this.reservationData = {}
            })
        },
        async saveReservation(item) {
            var _this = this
            this.progress = 0
            this.loading = true
            this.loadingSaveReservation = true
            var formData = new FormData()

            if (this.new_reserve_date) {
                formData.append('new_reserve_date', this.new_reserve_date)
            }
            if (this.reserve_time) {
                formData.append('reserve_time', this.reserve_time)
            }
            if (this.comment) {
                formData.append('comment', this.comment)
            }

            if (this.type_table) {
                if (this.type_table.id) {
                    formData.append('type_table', this.type_table.id)
                } else {
                    formData.append('type_table', this.type_table)
                }
            }
            if (this.table_number) {
                if (this.table_number.id) {
                    formData.append('table_number', this.table_number.id)
                } else {
                    formData.append('table_number', this.table_number)
                }
            }
            if (this.people) {
                formData.append('people', this.people)
            }

            if (this.language) {
                formData.append('language', this.language)
            }

            if (this.reserve_id) {
                // Save

                await this.$http
                    .put(`admin/restaurant_reservation/${this.reserve_id}`, formData, {
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {

                        this.$toastr.success(this.$t('reserve_has_been_updated'))
                        this.closeDialogReservation()
                        this.getReservations();

                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('reserve_has_not_been_updated'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                        this.loadingSaveReservation = false
                    })
            }
        },
        async getRestaurant() {
            this.loadingRestaurant = true;
            let params = {}
            if (this.language) {
                params.language = this.language
            }
            await this.$http
                .get(`admin/restaurant/`, {params: params})
                .then(res => {
                    this.itemRestaurant = res.body.data
                    this.restaurant = parseInt(this.$route.params.restaurant) ?? null
                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_get_list_restaurants'));
                    this.itemRestaurant = []

                })
                .finally(end => {
                    this.loadingRestaurant = false
                });
        },
        async getTableNumber(type_table) {
            this.loadingTableNumber = true;
            let params = {}
            if (this.language) {
                params.language = this.language

            }
            if(type_table){
                params.type_table = type_table
            }

            params.filter = 'search'
            await this.$http
                .get(`admin/restaurant_table/`, {params: params})
                .then(res => {
                    this.itemTableNumber = res.body.data
                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_get_list_table_numbers'));
                    this.itemTableNumber = []

                })
                .finally(end => {
                    this.loadingTableNumber = false
                });
        },
        async showDetailsReservation(item) {
            this.loading = true;
            this.detailsItems = item
            this.dialogDetails = true;
            this.loading = false;

        },
        async getReservations() {
            var _this = this
            this.loading = true;
            const {
                sortBy,
                sortDesc,
                page,
                itemsPerPage
            } = this.options
            let params = {}
            if (sortBy[0] !== undefined) {
                params.sortBy = sortBy[0]
            }
            if (sortDesc[0] !== undefined) {
                params.sortDir = sortDesc[0] ? 'asc' : 'desc'
            }
            if (page !== undefined) {
                params.page = page
            }
            if (itemsPerPage !== undefined) {
                params.perPage = itemsPerPage
            }

            if (this.lang) {
                params.language = this.lang
            }
            if (this.phone) {
                params.phone = this.phone
            }

            if (this.reserve_date) {
                params.reserve_date = this.reserve_date
            }

            if (this.restaurant) {
                if (this.restaurant.id) {
                    params.restaurant =  this.restaurant.id
                } else {
                    params.restaurant =  this.restaurant
                }
            }

            await this.$http
                .get("admin/restaurant_reservation", {
                    params: params,
                })
                .then(res => {
                    this.reservationItems = res.body.data
                    this.page = res.body.meta.current_page
                    this.totalReservations = res.body.meta.total
                    this.pageCount = res.body.meta.last_page

                })
                .catch(err => {
                    this.reservationItems = []
                    this.page = 0
                    this.totalReservations = 0
                    this.pageCount = 0
                    this.$toastr.error(this.$t('failed_to_get_list_reservations'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.loading = false
                })
        },
        async CancelReservation(item) {
            if (confirm(this.$t('cancel_reservation'))) {
                var _this = this
                this.loading = true
                await this.$http
                    .delete(`admin/restaurant_reservation/${item.ulid}`)
                    .then(res => {
                        this.$toastr.success(this.$t('reservation_has_been_cancel'))
                        this.getReservations()
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('reservation_has_not_been_cancel'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }
        },
        cancelDetails(){
            this.dialogDetails = false;
        },

    }
}
</script>
